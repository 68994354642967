import React from 'react'
import Layout from '../components/layout'
import NewHeader from '../components/NewHeader'
import MembershipImage from '../images/newblogs.jpg'
import SEO from '../components/seo'
const Blogs = () => {
  return (
    <div>
      <Layout>
        <SEO title="Blogs" />
        <NewHeader title="Blogs" image={MembershipImage} />
      </Layout>
    </div>
  )
}

export default Blogs
